import Vue from 'vue'
// 引入文件而已, 不一定要export, 可见index.vue是每一个单页的骨架
import App from './index.vue'
// 每一个组件都对应一个router
import router from './router'
// 组件状态
//import store from '@/store/'
// 页面入口
import entryConfig from '_lib/entryConfig/'
// 引入组件
import iView from '@/components';
import "@/styles/index.less";

// index.html 相当于在index.vue输出的app 头尾包装了css和js

// 调用公共方法加载配置
entryConfig(Vue)
Vue.use(iView)

new Vue({
  router,
  //store,
  render: h => h(App)
}).$mount('#app')
