// 这个router组件应该是在加载index.vue骨架之前，初始化页面各个部分的子组件
import Vue from 'vue'
import Router from 'vue-router'

// 首页
const home = (resolve => {
    require.ensure(['../views/home.vue'], () => {
        resolve(require('../views/home.vue'))
    })
})

Vue.use(Router)

let base = `${process.env.BASE_URL}`
//console.log(base)

let router = new Router({
    mode: 'history',
    base: base,
    routes: [
        {
            path: '/',
            name: 'home',
            component: home,
            meta: { title: '首页' }
        },
    ]
})

router.beforeEach((to, from, next) => {
    /*
    //let title = to.meta && to.meta.title
    let title = 'KKKKKKKKKKKKKKK'
    
    if (title) {
        document.title = title // 设置页面 title
    }
    
    /*if (to.name === 'home') {
    
        // 拦截并跳转至 page2 单页
        Vue.$openRouter({
            name: 'page2'
        });
    }*/
    
    next()
})

export default router
