<template>
    
    <swiper :options="swiperOption" class="swiper-box">
        <swiper-slide class="swiper-item -align-center slogan-page">
            <p class="slogan-subtitle"><strong>6年</strong>　互联网 / 嵌入式 / 机器视觉 </p>
            <img src="@/images/slogan.png"/>
            <div  class="studio-footer">
                <Row type="flex" justify="center" align="middle">
                    <Col :xs="24" :sm="24" :md="24">
                        <div class="cb-footer-right">
                            <div class="cb-footer-copy">© 2020 天津不工信息技术有限公司</div>
                        </div>
                    </Col>
                </Row>

                <Row type="flex" justify="center" align="middle">
                    <Col :xs="24" :sm="24" :md="24">
                        <div class="cb-footer-center">
                            <div class="cb-footer-link">
                                <a href="https://beian.miit.gov.cn/" target="_blank" class="studio-gov-info" >
                                    津ICP备19000583号-1
                                </a>
                            </div>
                            <div class="space">·</div>
                            <div class="cb-footer-link">
                                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12011602000322" class="studio-gov-info" >
                                    <img :src="gongan_beian">
                                    津公网安备 12011602000321号
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </swiper-slide>
    </swiper>

</template>

<script>
import img_gongan from '@/images/gongan.png'
export default {
    data() {
        return {
            swiperOption: {
                direction: 'vertical',
                slidesPerView: 1,
                spaceBetween: 0,
            },

            helperType: 'STUDIO',
            account: '',
            sender: '',
            content: '',
            gongan_beian: img_gongan
        }
    },
  }
</script>